<template>
  <v-card class="rounded">
    <!-- Show the subtext -->
    <div class="pl-8 pt-4 font-weight-bold">Campaign Audience Overview</div>

    <v-row class="mt-1">
      <!-- Show gender on left -->
      <v-col cols="12" md="6" lg="3">
        <div class="text-overline mb-2 pl-8">Gender Distribution</div>

        <gender-split :audience-data="data" />
      </v-col>

      <!-- Show age on right -->
      <v-col cols="12" md="6" lg="3">
        <div class="text-overline mb-2 pl-4">Age Distribution</div>

        <age-and-gender :audience-data="data" />
      </v-col>

      <!-- Show country locations on left -->
      <v-col cols="12" md="6" lg="3" v-if="data.geo.countries.length">
        <div class="text-overline mb-2 pl-4">Top Countries</div>

        <location-chart :data="data.geo.countries" />
      </v-col>

      <!-- Show city locations on left -->
      <v-col cols="12" md="6" lg="3" v-if="data.geo.cities.length">
        <div class="text-overline mb-2 pl-4">Top Cities</div>

        <location-chart :data="data.geo.cities" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
// Import children components
const GenderSplit = () => import(/* webpackChunkName: "charts-gender-split" */ "@/blocks/user-profile/charts/GenderSplit.vue")
const AgeAndGender = () => import(/* webpackChunkName: "charts-age-gender" */ "@/blocks/user-profile/charts/AgeAndGender.vue")
const LocationChart = () => import(/* webpackChunkName: "charts-location" */ "@/blocks/user-profile/charts/Location")

// Export the SFC
export default {
  // Name of the component
  name: "CRMAudience",

  // Accept incoming data from parent
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  // Register children components
  components: {
    GenderSplit,
    AgeAndGender,
    LocationChart
  }
}
</script>
